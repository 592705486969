import { useMutation, useQueries, useQuery } from 'react-query'
import { Campaign } from '@ospace/schemas'

import { CampaignService } from '../services'
import { ProductService } from '../services/product'

export const useCampaigns = (enabled: boolean = true) => {
  return useQuery<Campaign[]>(['campaigns'], CampaignService.getCampaigns, { enabled })
}

export const useCampaign = (id: number) => {
  return useQuery(['campaign', id], () => CampaignService.getCampaign(id))
}

export const useProductLines = () => {
  return useQuery(['product-lines'], () => ProductService.listProductLines())
}

export const useClientCampaign = (clientId: number, enabled?: boolean) => {
  return useQuery(
    ['client-campaign', clientId],
    () => CampaignService.findCampaignsByClient(clientId),
    { enabled }
  )
}

export const useClientsCampaigns = (clientIds: number[]) => {
  const queries = clientIds.map((clientId) => {
    return {
      enabled: !!clientId,
      queryKey: ['clients-campaign', clientId],
      queryFn: () => CampaignService.findCampaignsByClient(clientId),
    }
  })

  return useQueries(queries)
}

export const useCampaignDashboard = (campaignId: number, intelType: string) => {
  return useQuery(
    ['campaign', campaignId + intelType],
    () => CampaignService.getDashboards(campaignId),
    {
      refetchOnMount: true,
    }
  )
}

export const useDealStatus = (campaignId: number) => {
  return useQuery(['campaign-deal-status', campaignId], () =>
    CampaignService.getDealStatus(campaignId)
  )
}

export const useDeals = (campaignId: number) => {
  return useQuery(['campaign-deals', campaignId], () => CampaignService.getDeals(campaignId))
}

export const useContactsAndNotes = (dealId: number, campaignId: number) => {
  return useQuery(
    ['deal-contacts-notes', dealId, campaignId],
    () => CampaignService.getContactsAndNotes(dealId, campaignId),
    {
      enabled: !!dealId,
    }
  )
}

export const useDealViewMutation = (dealId: number, campaignId: number) => {
  return useMutation(['deal-viewed', dealId, campaignId], () =>
    CampaignService.postDealViewedDate(dealId, campaignId)
  )
}
